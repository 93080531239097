<template>
    <!-- <main-container> -->
        <div class="mobileContainer">
        <HeaderContainerOPDBooking>
            <BookingSuccessHeader/>
        </HeaderContainerOPDBooking>
        <div class="bodyContainer">
            <div style="width: 100%; height: 100%; background-color: white; margin-bottom: 20px;">
                <div style="display: flex; justify-content: center; align-items: center; flex-direction: column; gap: 4px; margin-top: 25px;">
                    <img src="https://s3iconimages.mymedicine.com.mm/successIconPoppers.gif" style="width: 85px;">
                    <div style="font-size: 16px; font-weight: 600;">{{$t("Customer.PackageAppointments.Package_booked_successfully")}}!</div>
                    <div style="font-size: 14px; font-weight: 400; color: #4f4f4f;">{{$t("Customer.PackageAppointments.your_package_is_confirmed")}}.</div>
                    <div style="font-size: 14px; font-weight: 400; color: #4f4f4f;">{{$t("Customer.PackageAppointments.booking_id")}}: <span style="font-size: 14px; font-weight: 500; color: #333333;">{{ bookingInfo.bookingID }}</span></div>
                </div>
                <div class="packageDetailContainer" >
                    <img :src="bookingInfo.packageBanner" alt="" style="border-top-left-radius: inherit; border-top-right-radius: inherit; height: 150px; object-fit: cover; width: 100%;">
                    <div style="width: 100%; padding: 16px; padding-top: 0; gap: 16px; display: flex; flex-direction: column; ">
                        <OPDPackageNameAndTestDetails  :showApplicableFor="true" :showBookSlot="false" :showPrice="false"/>
                        <div style="display: flex; flex-direction: column; gap: 4px; align-items: flex-start;">
                            <span style="color: #828282; font-size: 14px; font-weight: 400;">{{$t("Customer.PackageAppointments.slot_time")}}: </span>
                            <div style="font-size: 14px; font-weight: 500; color: #333333;"> {{ bookingInfo.timeInfo }} ({{ formatDate(bookingInfo.bookingDateNumber) }})   </div>
                            
                        </div>
                        <div style="display: flex; flex-direction: column; gap: 4px; align-items: flex-start;">
                            <span style="color: #828282; font-size: 14px; font-weight: 400;">{{$t("Customer.CartPage.Address")}}: </span>
                            <div class="hospitalNameContainer">
                                <div class="hospitalLogo">
                                    <img :src="bookingInfo.hospitalLogo" style="max-width: 100%; max-height: 100%; object-fit: contain; border-radius: 8px;"/>
                                </div>
                                <div class="hospitalName">
                                    <div style="font-size: 14px; font-weight: 500; color: #333333;">{{ bookingInfo.hospitalName }}</div>
                                    <div style="font-size: 12px; font-weight: 400; color: #4f4f4f;">{{ bookingInfo.hospitalAddress }}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <PatientInfoComponent :bookingInfo="this.bookingInfo"/> 
                <div style="display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start; margin-top: 25px;">
                    <div style="font-size: 14px; font-weight: 600; margin-bottom: 10px;">{{$t("Customer.PackageAppointments.payment_info")}}</div>
                    <div style="width: 100%; display: flex; flex-direction: column; justify-content: flex-start; gap: 20px; border: 1px solid #e0e0e0; border-radius: 8px; padding: 16px;">
                        <div style="display: flex; align-items: center; justify-content: space-between;">
                            <span style="font-weight: 500; font-size: 14px;">{{$t("Customer.PackageAppointments.Total_amount")}}:</span>
                            <span style="font-weight: 600; font-size: 16px; color: #48acef;">{{ bookingInfo.price }} MMK</span>
                        </div>
                    </div>
                </div>
                <Div style="margin-top: 100px;"></Div>
            </div>
        </div>
        <div class="bottomBar">
            <div @click="goToPage('OPDPackageHome')" style="display: flex; justify-content: center; align-items: center; padding: 8px; border: 1px solid #48acef; width: 30%; border-radius: 8px; cursor: pointer;">
                <div style="color: #48acef;" >{{$t("Customer.PackageAppointments.Home")}}</div>
            </div>
            <div @click="goToPage('ListPackageAppointments')" style="display: flex; justify-content: center; align-items: center; padding: 8px; border: 1px solid #48acef; background: #48acef; width: 60%; border-radius: 8px; cursor: pointer;">
                <div style="color: #fff;" >{{$t("Customer.PackageAppointments.My_bookings")}}</div>
            </div>
        </div>
        <!-- <div v-if="viewDoctorModel" transition="slide-y-transition">
            <view-doctor-component @toggleViewDoctorDialog="toggleViewDoctorDialog" :doctorInfo="doctorData" :modelValue="viewDoctorModel"/>
        </div>     -->
    </div>
    <!-- </main-container> -->
</template>

<script>
import axios from "axios";
import { axios_auth_instance_customer, axios_auth_instance_hba_customer } from '../../../utils/axios_utils';
import HeaderContainerOPDBooking from '../../../templates/headerContainerOPDBooking.vue';
import BookingSuccessHeader from '../../OPDBookingPages/componentUsedInPage/bookingSuccessPage/bookingSuccessHeader.vue';
import PatientInfoComponent from '../../OPDBookingPages/componentUsedInPage/bookingSuccessPage/patientInfoComponent.vue';
import OPDPackageNameAndTestDetails from '../componentsUsedInPages/OPDPackageViewHospitalPage/OPDPackageNameAndTestDetails.vue';

export default {
  components: {HeaderContainerOPDBooking, BookingSuccessHeader, PatientInfoComponent, OPDPackageNameAndTestDetails},
    name: 'HospitalPackageBookingSuccessPage',
    
    data: function() {
        return {
            token: null,
            viewDoctorModel: false,
            todayAppointment: [],
            pageLoading: true,
            ongoingAppointment: [],
            bannerURL: '',
            scrollableDiv: null,
            searchMeDiv: null,
            allDetailsDiv: null,
            dependent_members: [],
            currentCustomer: null,
            doctorList: [],
            // bookingID: null,
            bookingInfo: {
                patientInfo: {
                    name: 'Prakersh',
                    age: 23,
                    gender: 'Male'
                }
            },
            doctorInfo: null,
            doctorData: null,
        };
    },
    computed: {
        showHospitalLoader () {
            return this.$store.state.particularHospitalState.fetchingData;        
        },
        getDocotorList() {
            return this.$store.state.particularHospitalState.shownDoctorList || [];        
        }
    },
    async mounted() {
        if (!window.xm)
			this.token = this.$cookies.get('customerToken');
		else
			this.token = localStorage.getItem('customerToken');

        this.disableBackNavigation();
        this.bookingInfo = this.$route.params.bookingInfo;
        // try {
        //     let apiResponse = await axios_auth_instance_hba_customer.get(`/opd/bookingDetails/${this.bookingID}`);
        //     this.bookingInfo = apiResponse.data.bookingDetails;
        // } catch(error) {
        //     console.log(error);
        // }

        this.userPageViewTable();
    },

    methods: {
        userPageViewTable() {
            if (this.token === null) {
                var userPageViewTableBody = {
                    pageName : 'PackageBookSuccessPage',
                    typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                    pageName: 'PackageBookSuccessPage',
                    typeOfUser: 'CUSTOMER',
                    token: this.token
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
        },
        formatDate(inputDate) {
    inputDate = inputDate.toString();

    let year = inputDate.slice(0, 4);
    let month = inputDate.slice(4, 6);
    let day = inputDate.slice(6, 8);

    return `${year}-${month}-${day}`;
},
        async toggleViewDoctorDialog(value) {
            if(value) {
                try {
                    if (this.doctorData == null) {
                        let apiResponse = await axios_auth_instance_hba_customer(`/opd/doctorInfo/${this.bookingInfo.doctorInfo.doctorID}`);
                        this.doctorData = apiResponse.data.doctorDetails;
                    }
                    this.viewDoctorModel = true;
                } catch (error) {
                    console.log(error);
                }
            } else {
                this.viewDoctorModel = false;
            }
        },
        disableBackNavigation() {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = () => {
        this.$router.go(-1); // Prevent back navigation
      };
    },
        goToPage(value) {
            this.$router.push({
                name: value,
            });
        },
    },
};

</script>
<style  scoped>
.TokenContainer {
    width: 150px;
    height: 44px;
    padding: 8px 8px 8px 16px;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 16px;
    background: #1467BF1A;
}
.mobileContainer {
    margin: auto;
    width: 425px;
    background-color: white;
}
.bodyContainer {
    width: 100%;
    padding: 0 16px;
    height: 90vh;
    overflow: auto;
    background-color: white;
    /* padding-bottom: 100px; */
}
.bottomBar {
    position: absolute;
    bottom: 0;
    box-shadow: 0px -4px 8px 0px #0000000A;
    width: 100%;
    max-width: 425px;
    display: flex;
    align-items: center;
    padding: 15px;
    justify-content: space-evenly;
    z-index: 1;
    background: white;
}
.bottomSheetContainer {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 70vh;
    background: #fff;
    border: 1px solid #ebebe8;
    border-radius: 16px;
    max-width: 425px;
    max-height: 70vh;
    overflow-y: scroll;
    z-index: 1000;
}
.doctorBodyContainer {
    padding: 32px 16px 32px 16px;
}
.experienceBox {
    padding: 8px 16px 8px 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}
.bottomSheetHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 12px 16px;
    border-bottom: 1px solid #e8ebeb;
}
.packageDetailContainer {
    background: white;
    border-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid #e0e0e0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    cursor: pointer;
    margin-top: 25px;
}
.hospitalNameContainer {
    display: flex;
    gap: 8px;
}
.hospitalLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 56px;
    max-width: 56px;
    min-height: 56px;
    min-width: 56px;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}
.hospitalName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
@media screen and (max-width: 425px){
.mobileContainer {
    width: 100%;
}
}
</style>